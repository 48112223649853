import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LoginComponent} from './login/login.component';
import {FormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {VinsComponent} from './vins/vins.component';
import {PasswordModule} from "primeng/password";
import {CommandsComponent} from './commands/commands.component';
import {ToastModule} from "primeng/toast";
import { ManageVehiclesComponent } from './manage-vehicles/manage-vehicles.component';
import {DialogModule} from "primeng/dialog";
import {RadioButtonModule} from "primeng/radiobutton";
import {CheckboxModule} from "primeng/checkbox";
import {MessageService} from "primeng/api";
import {ApiService} from "./api/api.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LoginModule} from "./login/login.module";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {InputMaskModule} from "primeng/inputmask";
import {InputNumberModule} from "primeng/inputnumber";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {AccordionModule} from "primeng/accordion";
import { MessageCenterComponent } from './message-center/message-center.component';
import {TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {InputSwitchModule} from "primeng/inputswitch";
import {CalendarModule} from "primeng/calendar";
import {TooltipModule} from "primeng/tooltip";
import {PanelModule} from "primeng/panel";
import {UserIdService} from '@wame/ngx-adfs';
import { environment } from 'src/environments/environment';
import {
  MSAL_GUARD_CONFIG, MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard, MsalGuardConfiguration,
  MsalInterceptor, MsalInterceptorConfiguration, MsalModule,
  MsalService
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import { LoginFailedComponent } from './login-failed/login-failed.component';
import { LogoutComponent } from './logout/logout.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.clientId,
      authority: environment.msalConfig.authority
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);
  // add additional scopes to protectedResourceMap here

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes]
    },
    loginFailedRoute: '/login-failed'
  };
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
  // add additional logging function here as desired.
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    VinsComponent,
    CommandsComponent,
    ManageVehiclesComponent,
    MessageCenterComponent,
    LoginFailedComponent,
    LogoutComponent,
  ],
    imports: [
        LoginModule,
        HttpClientModule,
        BrowserModule,
        RouterModule,
        AppRoutingModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        BrowserAnimationsModule,
        MatDividerModule,
        FormsModule,
        DropdownModule,
        ButtonModule,
        InputTextModule,
        PasswordModule,
        ToastModule,
        DialogModule,
        RadioButtonModule,
        CheckboxModule,
        ProgressSpinnerModule,
        InputMaskModule,
        InputNumberModule,
        ConfirmDialogModule,
        AccordionModule,
        TableModule,
        RippleModule,
        InputSwitchModule,
        CalendarModule,
        TooltipModule,
        PanelModule,
        MsalModule
    ],
  providers: [MessageService, ApiService,	UserIdService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
