export const environment = {
  production: true,
  api_base_url: 'https://api-stg.int.vehicle.ford.com/api/mappsim',
  env: ' STG ',
  b2c_redirect_uri: 'https://mappsim.ford.com/',
  otp_redirect_base_url: 'https://mappsim.ford.com/',
  b2c_clientId: '805cf4c1-77ab-4e57-9a91-0ff75ab6f6bd',
  ford_application_id: 'DFD59BC8-4B07-44ED-A91A-4EE77E71E6AD',
  b2c_scope: '805cf4c1-77ab-4e57-9a91-0ff75ab6f6bd openid',
  b2c_loginUrl: 'https://loginqa.ford.com/e75ff8bf-6627-43e3-b410-9fc74d252d29/B2C_1A_QA_SignInSignUp_en-US/oauth2/v2.0/authorize?ui_locales=en-US&country_code=USA&language_code=en-US&response_type=code',
  msalConfig: {
    clientId: 'ff7c8fd4-7bfd-4bd1-bb88-6e585e520073',
    authority: 'https://login.microsoftonline.com/c990bb7a-51f4-439b-bd36-9c07fb1041c0/'
  },
  apiConfig: {
    scopes: ['api://e43e3fd9-9d56-419b-9114-1ea2d2f8ab4b/.default'],
    uri: 'https://api-stg.int.vehicle.ford.com/api/mappsim'
  }

};
