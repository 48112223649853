
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {LoginService} from './login.service';
import {B2CLoginResponse, LoginUserResponse} from './login.model';
import {environment} from '../../environments/environment';
import {EventService} from "../shared/event.service";
import {filter, Subject, takeUntil} from "rxjs";
import {InteractionStatus} from "@azure/msal-browser";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit,OnDestroy {
  loading: boolean;
  private readonly _destroying$ = new Subject<void>();
  constructor(private loginService: LoginService,
              private router: Router,
              private messageService: MessageService,
              private eventService: EventService,
              private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService) {
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
    sessionStorage.removeItem('newUserEmail');
    sessionStorage.removeItem('newUserLanguage');
    sessionStorage.removeItem('newUserCountry');
    sessionStorage.removeItem('newUserOTP');

    sessionStorage.setItem('appId',environment.ford_application_id);

     if (sessionStorage.getItem('code') && sessionStorage.getItem('code') !== null) {
      this.loading = true;
      this.loginService.b2cLogin(sessionStorage.getItem('code')).subscribe((response: B2CLoginResponse) => {
        if (response.code === 400) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong'
          });
          sessionStorage.removeItem('code');
        } else {
          sessionStorage.setItem('loginUser',response.userProfile);
          const userName = JSON.parse(decodeURIComponent(atob(response.userProfile)));
          this.notifyUserNameEvent(userName.name);
          sessionStorage.removeItem('selectedVin');
          sessionStorage.removeItem('code');
          this.notifyUserLoginEvent();
          this.router.navigate(['vins'])
            .then(null, error => {
              const message = 'There was an issue navigating to /vins.';
              this.messageService.add({
                severity: 'error',
                summary: 'Navigation Error',
                detail: message + '\n\n' + error
              });
            });
        }
      }, error => {
        sessionStorage.removeItem('code');
        this.messageService.add({
          severity: 'error', summary: 'Error: ' + error.status + ' ' + error.statusText,
          detail: 'An unexpected error occurred.'
        });
      }).add(() => {
        this.loading = false;
      });
    } else {
      window.location.replace(this.getRedirectUrl());
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  getRedirectUrl() : string {
    let _endPoint = environment.b2c_loginUrl +
      '&client_id=' + environment.b2c_clientId +
      '&scope=' + environment.b2c_scope +
      '&ford_application_id=' +environment.ford_application_id +
      '&redirect_uri=' + encodeURIComponent(environment.b2c_redirect_uri);
    return _endPoint;
  }

  notifyUserLoginEvent(){
    this.eventService.loginEvent.emit(true);
  }
  notifyUserNameEvent(name:string) {
    this.eventService.nameEvent.emit(name);
  }
}
