import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ConfirmationService, MessageService} from "primeng/api";
import {EventService} from "./shared/event.service";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {InteractionStatus} from "@azure/msal-browser";
import {filter, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'mobileapp-simulator-ui';
  userName = '';
  selectedVin = '';
  isLoggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private router: Router,
              private messageService: MessageService,
              private  eventService: EventService,
              private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService) {
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.eventService.vinEvent.subscribe(vin => {
      this.selectedVin = vin ? " " + vin: '';
    });

    this.eventService.nameEvent.subscribe(userName => {
      this.userName = userName;
    });

    this.eventService.loginEvent.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  handleClick(path: string) {
    if(path === 'manage-profile'){
      path = path +this.router.url;
    }
    this.router.navigate([path])
      .then(null, error => {
        const message = 'There was an issue navigating to /' + path + '.';
        this.messageService.add({severity: 'error', summary: 'Navigation Error', detail: message + '\n\n' + error});
      });
  }

  handleLogOut() {
    this.eventService.loginEvent.emit(false);
    sessionStorage.removeItem('loginUser');
    sessionStorage.removeItem('selectedVin');
    sessionStorage.removeItem('commandResults');
    sessionStorage.removeItem('isAULogin');
    sessionStorage.clear();
    this.selectedVin = '';
    this.userName = '';
    this.handleClick('logout');
  }
}
