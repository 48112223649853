import { Component } from '@angular/core';

@Component({
  selector: 'app-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['../app.component.css']
})
export class LoginFailedComponent {

}
