import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ApiService } from '../api/api.service';
import {AULoginCredentials, B2CLoginResponse, LoginUserResponse} from "./login.model";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {TmcResponse} from "../shared/shared-models.model";

@Injectable()
export class LoginService {

  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  login(request: string): Observable<LoginUserResponse> {
    return this.apiService.post<LoginUserResponse>(environment.api_base_url, '/login', request, {"Application-Id": sessionStorage.getItem('appId')});
  }

  b2cLogin(request: string): Observable<B2CLoginResponse> {
    return this.apiService.post<B2CLoginResponse>(environment.api_base_url, '/b2cLogin', request, {"Application-Id": sessionStorage.getItem('appId')});
  }

  auLogin(credentials: AULoginCredentials): Observable<TmcResponse> {
    return this.apiService.post<TmcResponse>(environment.api_base_url, '/auLogin', credentials);
  }
}
