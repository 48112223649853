<header class="app-header" id="app-header">
  <div id="app-branding">
    <div id="app-icon" style="margin-left : 2em ;">
      <img src="assets/image/FordOval.png" class="logo" alt="Ford Oval" (click)="handleClick('vins')"/>
    </div>
   <div id="app-name" class="app-name-style">
      Mobile App Simulator |
    </div>
    <span class='version'>TMC Version</span>
    <div id="user-details">
     {{userName}} | {{selectedVin}}
    </div>
    <div *ngIf="this.isLoggedIn">
      <button id="menu-bars-button" [matMenuTriggerFor]="menu" aria-label="Menu Dropdown">
        <mat-icon id="menu-bars-icon" fontSet="pi" fontIcon="pi-bars"></mat-icon>
      </button>
    </div>
  </div>
</header>

<main id="app-content">
  <div class="router-content">
    <router-outlet></router-outlet>
  </div>
</main>
<footer id="app-footer" class="ui-g">
  <div class="ui-g-12 text-center">
    <p i18n="@@copyright">&copy; Ford Motor Company</p>
  </div>
</footer>


<mat-menu #menu="matMenu">
  <button class="menu-item-button" mat-menu-item (click)="handleClick('manage-vehicles')">
    <span class="menu-item-text">Manage Vehicles</span>
  </button>
  <mat-divider></mat-divider>
  <button class="menu-item-button" mat-menu-item (click)="handleClick('commands')">
    <span class="menu-item-text">Commands</span>
  </button>
  <mat-divider></mat-divider>
  <button class="menu-item-button" mat-menu-item (click)="handleClick('message-center')">
    <span class="menu-item-text">Message Center</span>
  </button>
  <mat-divider></mat-divider>
  <button class="menu-item-button" mat-menu-item (click)="handleLogOut()">
    <span class="menu-item-text">Logout</span>
  </button>
</mat-menu>

<p-toast [preventOpenDuplicates]="true"></p-toast>
