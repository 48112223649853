import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['../app.component.css']
})
export class LogoutComponent {

  constructor(private router: Router) {
  }

  handleLogin() {
    this.router.navigate(['login']);
  }
}
